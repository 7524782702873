import React, { Suspense, useContext, lazy } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import makeStyles from "@material-ui/styles/makeStyles";
import PageContext from "@micado-digital/react-ui/context/PageContext";
import LayoutStandard from "../views/template/standard";
import LayoutSkiRegionDetail from "../views/template/ski-region-detail";
import LayoutSkiShop from "../views/template/ski-shop";
import Header from "../views/components/header";
import useIsCheckout from "@micado-digital/react-ski-shop/hooks/useIsCheckout";

const Animation = lazy(() =>
	import(/* webpackChunkName: "animation" */ "../views/components/animation")
);
const Footer = lazy(() =>
	import(/* webpackChunkName: "footer" */ "../views/components/footer")
);

const useStyles = makeStyles(theme => ({
	page: {
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		paddingTop: "calc(env(safe-area-inset-top))",
		overflow: "hidden",
		[theme.breakpoints.up("sm")]: {
			paddingTop: "calc(env(safe-area-inset-top))"
		}
	}
}));

const DetectLayout = () => {
	const [pageData] = useContext(PageContext);
	const { animationID, layoutTag, layoutVariant, menuGroups, state } = pageData || {};
	const css = useStyles();
	const skiShopURLPath = "ski-shop";
	const skiShopIsCheckout = useIsCheckout({ path: skiShopURLPath });

	if (isEmpty(pageData)) return <></>;

	const mainmenu = menuGroups?.find(item => item.title === "Hauptmenü");
	const metamenu = menuGroups?.find(item => item.title === "Metamenü");

	switch (layoutTag) {
		case "standard":
			return (
				<div className={clsx(css.page, "mco-page")}>
					<Header
						fixed={layoutVariant === "no-animation" || layoutVariant === "no-animation-top"}
						mainmenu={mainmenu}
						metamenu={metamenu}
					/>
					<Suspense fallback={<></>}>
						{layoutVariant !== "no-animation" && layoutVariant !== "no-animation-top" && (
							<Animation animationID={animationID} layoutVariant={layoutVariant} />
						)}
						<LayoutStandard variant={layoutVariant} />
						<Footer />
					</Suspense>
				</div>
			);

		case "ski-region-detail":
			return (
				<div className={clsx(css.page, "mco-page")}>
					<Header fixed mainmenu={mainmenu} metamenu={metamenu} />
					<Suspense fallback={<></>}>
						<LayoutSkiRegionDetail animationID={animationID} />
						<Footer />
					</Suspense>
				</div>
			);

		case "ski-shop":
			return (
				<div className={clsx(css.page, "mco-page")}>
					{!skiShopIsCheckout && <Header fixed mainmenu={mainmenu} metamenu={metamenu} />}
					<Suspense fallback={<></>}>
						<LayoutSkiShop hasHeader={!skiShopIsCheckout} />
						{!skiShopIsCheckout && <Footer />}
					</Suspense>
				</div>
			);

		default: {
			// errors

			// eslint-disable-next-line default-case
			switch (state) {
				case 404:
					return (
						<div className={clsx(css.page, "mco-page")}>
							<Header mainmenu={mainmenu} />
							<Suspense fallback={<></>}>
								<LayoutStandard variant={layoutVariant} />
								<Footer />
							</Suspense>
						</div>
					);
			}
		}
	}
};

export default DetectLayout;
