const locals = {
	de: {
		buttons: {
			newsletter: "Abonnieren",
			readMore: "Mehr erfahren",
			ticket: "Jetzt Karte kaufen"
		},
		errors: {
			noSearchEntries: "Keine Einträge gefunden!"
		},
		labels: {
			calcRoute: "Route berechnen",
			facilities: "Anlagen",
			from: "von",
			lift: "Lift",
			lifts: "Lifte",
			liftState: "Liftstatus",
			map: "Karte",
			newSnow: "Neuschnee",
			noValue: "Keine Angabe",
			pist: "Piste",
			pists: "Pisten",
			pistState: "Pistenstatus",
			region: "Region",
			scroll: "Scrollen",
			weather: "Wetter",
			webcams: "Webcams"
		},
		placeholders: {
			newsletter: "Newsletter anmelden...",
			searchField: "Suche nach..."
		},
		popUpLabel: "Scrollen",
		titles: {
			changeLanguage: "Sprache wechseln",
			weather: "3 - Tage Wetter"
		},
		urls: {
			newsletterSubscription: "/de/newsletter-anmeldung.html",
			search: "/de/suche.html"
		},
		interactiveMap: {
			headlines: {
				summer: "Hier gilt die 3TälerPass-Jahreskarte",
				winter: "Hier gilt die 3TälerPass-Jahres- & Saisonkarte"
			},
			labels: {
				winter: {
					seasonticket: "Saisonkarte & Jahreskarte",
					daytickets: "3TÄLER-Skipass (2 ½ bis 21 Tage)",
					showSkiAreas: "Skigebiete Anzeigen"
				},
				summer: {
					summerFacilities: "Sommerbahnen",
					outdoorPools: "Freibäder & Hallenbäder",
					climbingHall: "Kletterhalle",
					showLocations: "Standorte Anzeigen"
				}
			}
		}
	},
	en: {
		buttons: {
			newsletter: "Subscribe",
			readMore: "Read more",
			ticket: "Buy card now"
		},
		errors: {
			noSearchEntries: "No entries found!"
		},
		labels: {
			calcRoute: "Calculate route",
			facilities: "Facilities",
			from: "from",
			lift: "Lift",
			lifts: "Lifts",
			liftState: "Liftstate",
			map: "Map",
			newSnow: "New snow",
			noValue: "No value",
			pist: "Pist",
			pists: "Pists",
			pistState: "Piststate",
			region: "Region",
			scroll: "Scroll",
			weather: "Weather",
			webcams: "Webcams"
		},
		placeholders: {
			newsletter: "Subscribe to newsletter...",
			searchField: "Search..."
		},
		popUpLabel: "Scroll",
		titles: {
			changeLanguage: "Change language",
			weather: "3 - Days Weather"
		},
		urls: {
			newsletterSubscription: "/en/newsletter-subscription.html",
			search: "/en/search.html"
		},

		interactiveMap: {
			headlines: {
				summer: "The 3TälerPass annual pass is valid here",
				winter: "The 3TälerPass annual & season ticket is valid here"
			},
			labels: {
				winter: {
					seasonticket: "Season ticket & annual ticket",
					daytickets: "3TÄLER-Skipass (2 ½ until 21 days)",
					showSkiAreas: "Show ski regions"
				},
				summer: {
					summerFacilities: "Summer facilities",
					outdoorPools: "Outdoor and indoor pools",
					climbingHall: "Climbing hall",
					showLocations: "Show locations"
				}
			}
		}
	}
};

export default locals;
