const MapPoints = () => {
	const mapPoints = {
		winter: [
			{
				identifier: "alberschwende",
				title: "Alberschwende",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "andelsbuch-bezau",
				title: "Bezau",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "bach-joechelspitzbahn",
				title: "Bach Jöchelspitzbahn",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "balderschwang",
				title: "Balderschwang",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "schwarzenberg-boedele",
				title: "Bödele",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "boden-bschlabs",
				title: "Boden Bschlabs",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "buch",
				title: "Buch",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "damuels-mellau",
				title: "Damüls Mellau",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "diedamskopf",
				title: "Diedamskopf",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "dornbirn-ebnit",
				title: "Dornbirn Ebnit",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "schetteregg",
				title: "Egg Schetteregg",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "elbigenalp",
				title: "Elbigenalp",
				pageName: "/de/skiregion/diedamskopf.html",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "faschina",
				title: "Faschina",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "frastanz-bazora-gurtis",
				title: "Frastanz Bazora Gurtis",
				pageName: "",
				seasonCardOnly: true,
				type: "winter-lift"
			},
			{
				identifier: "furx",
				title: "Furx",
				pageName: "",
				seasonCardOnly: true,
				type: "winter-lift"
			},
			{
				identifier: "gramais",
				title: "Gramais",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "haeselgehr",
				title: "Häselgehr",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "hittisau",
				title: "Hittisau",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "hochhaederich",
				title: "Hochhäderich",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "karren",
				title: "Dornbirn Karrenseilbahn",
				pageName: "",
				seasonCardOnly: true,
				type: "winter-lift"
			},
			{
				identifier: "hohenems-schuttannen",
				title: "Hohenems Schuttannen",
				pageName: "",
				seasonCardOnly: true,
				type: "winter-lift"
			},
			{
				identifier: "holzgau",
				title: "Holzgau",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "huetten",
				title: "Bizau",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "laterns-gapfohl",
				title: "Laterns Gapfohl",
				pageName: "",
				seasonCardOnly: true,
				type: "winter-lift"
			},
			{
				identifier: "moeggers",
				title: "Möggers",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			// {
			// 	identifier: "oberreute-hochstraesslifte",
			// 	title: "Oberreute Hochsträßlifte",
			// 	pageName: "",
			// 	seasonCardOnly: true,
			// 	type: "winter-lift"
			// },
			{
				identifier: "oberstaufen-huendlebahn",
				title: "Oberstaufen Hündlebahn",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "oberstaufen-sinswang",
				title: "Oberstaufen Sinswang",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "raggal",
				title: "Raggal",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "reuthe",
				title: "Reuthe",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "hochlitten",
				title: "Riefensberg Hochlitten",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "sibratsgfaell-kraehenberg",
				title: "Sibratsgfäll Krähenberg",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "sonnenkopf",
				title: "Sonnenkopf",
				pageName: "",
				seasonCardOnly: true,
				type: "winter-lift"
			},
			{
				identifier: "sonntag-stein",
				title: "Sonntag Stein",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "stanzach",
				title: "Stanzach",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "steibis-hochgratbahn",
				title: "Steibis Hochgratbahn",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "steibis-imbergbahn",
				title: "Steibis Imbergbahn",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "sulzberg",
				title: "Sulzberg",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			},
			{
				identifier: "uebersaxen",
				title: "Übersaxen",
				pageName: "",
				seasonCardOnly: true,
				type: "winter-lift"
			},
			{
				identifier: "warth-schroecken",
				title: "Warth Schröcken",
				pageName: "",
				seasonCardOnly: false,
				type: "winter-lift"
			}
		],
		summer: [
			{
				identifier: "andelsbuch",
				pageName: "",
				title: "Andelsbuch",
				type: "summer-lift"
			},
			{
				identifier: "bach-joechelspitzbahn",
				pageName: "",
				title: "Bach Jöchelspitzbahn",
				type: "summer-lift"
			},
			{
				identifier: "andelsbuch-bezau",
				pageName: "",
				title: "Bezau",
				type: "summer-lift"
			},
			{
				identifier: "damuels",
				pageName: "",
				title: "Damüls",
				type: "summer-lift"
			},
			{
				identifier: "diedamskopf",
				pageName: "",
				title: "Diedamskopf",
				type: "summer-lift"
			},
			{
				identifier: "faschina",
				pageName: "",
				title: "Faschina",
				type: "summer-lift"
			},
			{
				identifier: "laterns-gapfohl",
				pageName: "",
				title: "Laterns Gapfohl",
				type: "summer-lift"
			},
			{
				identifier: "damuels",
				pageName: "",
				title: "Damüls Mellau",
				type: "summer-lift"
			},
			// {
			// 	identifier: "oberstaufen-sinswang",
			// 	pageName: "",
			// 	title: "Oberstaufen Sinswang",
			// 	type: "summer-lift"
			// },
			{
				identifier: "oberstaufen-huendlebahn",
				pageName: "",
				title: "Oberstaufen Hündlebahn",
				type: "summer-lift"
			},
			{
				identifier: "sonnenkopf",
				pageName: "",
				title: "Sonnenkopf",
				type: "summer-lift"
			},
			{
				identifier: "sonntag-stein",
				pageName: "",
				title: "Sonntag Stein",
				type: "summer-lift"
			},
			{
				identifier: "steibis-hochgratbahn",
				title: "Steibis Hochgratbahn",
				pageName: "",
				type: "summer-lift"
			},
			{
				identifier: "steibis-imbergbahn",
				pageName: "",
				title: "Steibis Imbergbahn",
				type: "summer-lift"
			},
			{
				identifier: "warth-schroecken",
				pageName: "",
				title: "Warth",
				type: "summer-lift"
			},
			{
				identifier: "mellau",
				pageName: "",
				title: "Mellau",
				type: "summer-lift"
			},
			{
				identifier: "karren",
				pageName: "",
				title: "Dornbirn Karrenseilbahn",
				type: "summer-lift"
			},
			{
				identifier: "au_1",
				title: "Au",
				type: "outdoor-pool"
			},
			{
				identifier: "schoppernau_1",
				title: "Schoppernau",
				type: "outdoor-pool"
			},
			{
				identifier: "hittisau_1",
				title: "Hittisau",
				type: "outdoor-pool"
			},
			{
				identifier: "egg_1",
				title: "Egg",
				type: "outdoor-pool"
			},
			{
				identifier: "schwarzenberg_1",
				title: "Schwarzenberg",
				type: "outdoor-pool"
			},
			{
				identifier: "bezau_1",
				title: "Bezau",
				type: "outdoor-pool"
			},
			{
				identifier: "dornbirn-waldbad-enz_1",
				title: "Dornbirn Waldbad Enz",
				type: "outdoor-pool"
			},
			{
				identifier: "dornbirn-stadtbad_1",
				title: "Dornbirn stadtbad",
				type: "outdoor-pool"
			},
			{
				identifier: "mellau_1",
				title: "Mellau",
				type: "outdoor-pool"
			},
			{
				identifier: "nenzing-waldgaubad_1",
				title: "Nenzing Walgaubad",
				type: "outdoor-pool"
			},
			{
				identifier: "dornbirn-k1_1",
				title: "Dornbirn K1 Kletterhalle",
				type: "climbing-hall"
			},
			{
				identifier: "klimmerei-buers",
				title: "Klimmerei Bürs",
				type: "climbing-hall"
			}
		]
	};

	return mapPoints;
};

export default MapPoints;
