import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Element } from "react-scroll";
import UIContainer from "@material-ui/core/Container";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
	margin: {
		marginTop: theme?.spacing(10),
		[theme.breakpoints.up("sm")]: {
			marginTop: theme?.spacing(12)
		}
	},
	noPadding: {
		paddingLeft: 0,
		paddingRight: 0
	},
	relative: {
		position: "relative"
	}
}));

const Container = props => {
	const {
		anchor,
		className,
		margin = true,
		maxWidth = false,
		name,
		noPadding,
		position,
		variant
	} = props;
	const css = useStyles();

	return (
		<UIContainer
			className={clsx(
				"mco-container",
				{
					"mco-container-margin": margin
				},
				{
					[css.margin]: margin,
					[css.noPadding]: noPadding,
					[css.relative]: position === "relative"
				},
				"mco-container-" + maxWidth,
				{
					["mco-container-" + name]: name
				},
				{
					["mco-container-" + name + "-" + variant]: variant
				},
				className
			)}
			maxWidth={maxWidth}
		>
			<Element name={anchor}>{props.children}</Element>
		</UIContainer>
	);
};

Container.propTypes = {
	margin: PropTypes.bool,
	maxWidth: PropTypes.string,
	name: PropTypes.string,
	variant: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default Container;
