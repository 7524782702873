import React, { Suspense, useContext, lazy } from "react";
import { useQuery } from "react-query";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import WebProfileContext from "@micado-digital/react-ui/context/WebProfileContext";
import ReactMetaData01 from "@micado-digital/react-metadata/ReactMetadata01";
import PageContext from "@micado-digital/react-ui/context/PageContext";
import SkiShopContext from "@micado-digital/react-ski-shop/context/SkiShopContext";
import Container from "../../../helper/container";

const Animation = lazy(() =>
	import(/* webpackChunkName: "animation" */ "../../components/animation")
);
const Cart01 = lazy(() =>
	import(
		/* webpackChunkName: "reactSkiShopCart" */ "@micado-digital/react-ski-shop/views/Cart01"
	)
);
const SkiRegionData = lazy(() =>
	import(/* webpackChunkName: "reactSkiRegionData" */ "../../../components/ski-region-data")
);
const SkiRegionGallery = lazy(() =>
	import(
		/* webpackChunkName: "reactSkiRegionGallery" */ "../../../components/ski-region-gallery"
	)
);
const SkiRegionKeyfacts = lazy(() =>
	import(
		/* webpackChunkName: "reactSkiRegionKeyfacts" */ "../../../components/ski-region-keyfacts"
	)
);
const ReactHeadline01 = lazy(() =>
	import(
		/* webpackChunkName: "reactHeadline01" */ "@micado-digital/react-headline/ReactHeadline01"
	)
);
const ReactText01 = lazy(() =>
	import(/* webpackChunkName: "reactText01" */ "@micado-digital/react-text/ReactText01")
);
const ReactPwaAlert = lazy(() =>
	import(
		/* webpackChunkName: "reactPwaAlert" */ "@micado-digital/react-pwa-alert/ReactPwaAlert"
	)
);

const useStyles = makeStyles(theme => ({
	content: {
		background: theme.palette?.background?.body,
		padding: theme?.spacing(0, 0, 10),
		position: "relative",
		scrollMarginTop: "70px",
		zIndex: 3,
		[theme.breakpoints.up("sm")]: {
			padding: theme?.spacing(0, 0, 10)
		}
	}
}));

const useAnimationData = (lang, pathname) => {
	return useQuery(["ski-region-animation", pathname], async () => {
		const { REACT_APP_PATH } = process.env;

		const res = await fetch(
			`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.RegionPlugin.RegionApiIO/AnimationList.api?lang=${lang}&pagename=${pathname}`
		);

		const data = await res.json();

		return data;
	});
};

const useData = (lang, pathname) => {
	return useQuery(["ski-region-detail", pathname], async () => {
		const { REACT_APP_PATH } = process.env;

		const res = await fetch(
			`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.RegionPlugin.RegionApiIO/Details.api?lang=${lang}&pagename=${pathname}`
		);

		const data = await res.json();

		return data;
	});
};

const SkiRegionDetail = ({ animationID }) => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { lang } = pageData || {};
	const [profileData] = useContext(WebProfileContext);
	const { skiShopData, setSkiShopData } = useContext(SkiShopContext);
	const { showCart } = skiShopData || {};
	const css = useStyles();

	const pathname = window.location.pathname;

	const { data: animationData } = useAnimationData(lang, pathname);
	const { data } = useData(lang, pathname);

	const facilityStatistics = data?.facilityStatistics;

	const liftStatistics = facilityStatistics?.filter(
		facility => facility?.identifier === "lift"
	)?.[0];

	const pistStatistics = facilityStatistics?.filter(
		facility => facility?.identifier === "piste"
	)?.[0];

	const description = data?.descriptions
		?.filter(description => description.type === "standard")?.[0]
		?.description?.replace(/(<([^>]+)>)/gi, "");

	const handleCartClose = () => {
		setSkiShopData(prevState => ({
			...prevState,
			showCart: false
		}));
	};

	return (
		<>
			<ReactMetaData01 description={description} title={data?.title} />
			<Suspense fallback={<></>}>
				<Animation
					animationID={animationID}
					data={animationData}
					layoutVariant="region-detail"
				/>
			</Suspense>
			<main className={clsx("mco-content", css.content)}>
				<Suspense fallback={<></>}>
					<SkiRegionKeyfacts
						facilitiesOpenCount={liftStatistics?.countOpen}
						facilitiesTotalCount={liftStatistics?.count}
						logo={data?.logo}
						pistsLengthOpenCount={pistStatistics?.countOpen}
						pistsLengthTotalCount={pistStatistics?.count}
						properties={data?.properties}
						state={data?.openingState}
						title={data?.title}
						weather={data?.weather}
					/>
				</Suspense>
				<Container maxWidth="md" name="headline" variant={1}>
					<Suspense fallback={<></>}>
						<ReactHeadline01 text={data?.title ?? ""} />
					</Suspense>
				</Container>
				{data?.descriptions
					?.filter(description => description.type === "standard")
					?.map(({ id, description }) => {
						return (
							<Container key={id} maxWidth="md" name="text">
								<Suspense fallback={<></>}>
									<ReactText01 text={description} />
								</Suspense>
							</Container>
						);
					})}
				<Suspense fallback={<></>}>
					<SkiRegionData
						city={data?.city}
						lang={lang}
						mail={data?.mail}
						phone={data?.phone}
						region={data?.identifier}
						street={data?.street}
						title={data?.title}
						zip={data?.zip}
					/>
				</Suspense>
				{data?.gallery && (
					<Suspense fallback={<></>}>
						<SkiRegionGallery elements={data?.gallery} />
					</Suspense>
				)}
				{profileData?.pwaShowInstallHint && (
					<Suspense fallback={<></>}>
						<ReactPwaAlert appIcon={REACT_APP_PATH + "/img/pwa/apple-icon-180.png"} />
					</Suspense>
				)}
			</main>
			<micado-ial path={REACT_APP_PATH}></micado-ial>
			{showCart && (
				<Suspense fallback={<></>}>
					<Cart01 lang={lang} onClose={handleCartClose} />
				</Suspense>
			)}
		</>
	);
};

export default SkiRegionDetail;
