import React, { Suspense, lazy } from "react";
import cloneDeep from "lodash/cloneDeep";
import Container from "../../../helper/container";
import MapPoints from "../../../utils/mapPoints";
import SearchResultContent from "../../../components/search-result-content";
import locals from "../../../locals";

const AdditionalBenefits = lazy(() =>
	import(
		/* webpackChunkName: "reactAdditionalBenefits" */ "../../../components/additional-benefits"
	)
);
const AnimationData = lazy(() =>
	import(/* webpackChunkName: "reactAnimationData" */ "../../../components/animation-data")
);
const Button = lazy(() =>
	import(/* webpackChunkName: "reactButton" */ "../../../components/button")
);
const DropDown = lazy(() =>
	import(/* webpackChunkName: "reactDropDown" */ "../../../components/dropdown")
);
const HeadlineOverline = lazy(() =>
	import(
		/* webpackChunkName: "reactHeadlineOverline" */ "../../../components/headline-overline"
	)
);
const Keyfacts = lazy(() =>
	import(/* webpackChunkName: "reactKeyfacts" */ "../../../components/keyfacts")
);
const List = lazy(() =>
	import(/* webpackChunkName: "reactList" */ "../../../components/list")
);
const ReactSearchResult = lazy(() =>
	import(/* webpackChunkName: "reactSearchResult" */ "../../../components/ReactSearchResult")
);
const SkiRegionList = lazy(() =>
	import(/* webpackChunkName: "reactSkiRegionList" */ "../../../components/ski-region-list")
);
const SkiRegionSlider = lazy(() =>
	import(
		/* webpackChunkName: "reactSkiRegionSlider" */ "../../../components/ski-region-slider"
	)
);
const Tickets = lazy(() =>
	import(/* webpackChunkName: "reactTickets" */ "../../../components/tickets")
);
const WebcamSlider = lazy(() =>
	import(/* webpackChunkName: "reactWebcamSlider" */ "../../../components/webcam-slider")
);
const InteractiveMap = lazy(() =>
	import(/* webpackChunkName: "reactInteractiveMap" */ "../../../components/interactive-map")
);
const ReactSVGIcon = lazy(() =>
	import(/* webpackChunkName: "reactSVGIcon" */ "@micado-digital/react-svg-icon/ReactSVGIcon")
);
const ReactHeadline01 = lazy(() =>
	import(
		/* webpackChunkName: "reactHeadline01" */ "@micado-digital/react-headline/ReactHeadline01"
	)
);
const ReactFile01 = lazy(() =>
	import(/* webpackChunkName: "reactFile01" */ "@micado-digital/react-file/ReactFile01")
);
const ReactFormWeb01 = lazy(() =>
	import(/* webpackChunkName: "reactFormWeb01" */ "@micado-digital/react-form/ReactFormWeb01")
);
const ReactGallery01 = lazy(() =>
	import(
		/* webpackChunkName: "reactGallery01" */ "@micado-digital/react-gallery/ReactGallery01"
	)
);
const ReactList01 = lazy(() =>
	import(/* webpackChunkName: "reactList01" */ "@micado-digital/react-list/ReactList01")
);
const ReactText01 = lazy(() =>
	import(/* webpackChunkName: "reactText01" */ "@micado-digital/react-text/ReactText01")
);
const ReactImageText01 = lazy(() =>
	import(
		/* webpackChunkName: "reactImageText01" */ "@micado-digital/react-imagetext/ReactImageText01"
	)
);
const ReactReferences01 = lazy(() =>
	import(
		/* webpackChunkName: "reactReferences01" */ "@micado-digital/react-references/ReactReferences01"
	)
);
const ReactSingleImage01 = lazy(() =>
	import(
		/* webpackChunkName: "singleImage01" */ "@micado-digital/react-singleimage/ReactSingleImage01"
	)
);
const ReactSingleTeaser01 = lazy(() =>
	import(
		/* webpackChunkName: "reactSingleTeaser01" */ "@micado-digital/react-singleteaser/ReactSingleTeaser01"
	)
);
const ReactTable01 = lazy(() =>
	import(/* webpackChunkName: "reactTable01" */ "@micado-digital/react-table/ReactTable01")
);
const ReactTeaser01 = lazy(() =>
	import(/* webpackChunkName: "reactTeaser01" */ "@micado-digital/react-teaser/ReactTeaser01")
);
const ReactTeaserList01 = lazy(() =>
	import(
		/* webpackChunkName: "reactTeaserList01" */ "@micado-digital/react-teaser-list/ReactTeaserList01"
	)
);
const ReactVideo01 = lazy(() =>
	import(/* webpackChunkName: "reactVideo01" */ "@micado-digital/react-video/ReactVideo01")
);
const ReactRates01 = lazy(() =>
	import(
		/* webpackChunkName: "reactReactRates01" */ "@micado-digital/react-rates/ReactRates01"
	)
);

const Elements = ({ items, lang, layoutVariant }) => {
	if (!items) return <></>;

	let isInDropdown = false;
	let isDropdownStartTag = false;
	let isDropdownEndTag = false;
	let currentDropdown = null;

	let newElements = cloneDeep(items);

	newElements = newElements.filter(item => {
		if (item.tag === "basic-dropdown") {
			if (item.option === 1) {
				currentDropdown = null;
				isDropdownStartTag = false;
				isDropdownEndTag = true;
				isInDropdown = false;
			} else {
				currentDropdown = item;
				isDropdownStartTag = true;
				isDropdownEndTag = false;
				isInDropdown = true;
			}
		} else {
			isDropdownStartTag = false;
			isDropdownEndTag = false;
		}

		if (isInDropdown && !isDropdownStartTag && currentDropdown) {
			if (currentDropdown._subItems) {
				currentDropdown._subItems.push(item);
			} else {
				currentDropdown._subItems = [item];
			}
		} else if (!isDropdownEndTag) {
			return item;
		}

		return null;
	});

	return (
		<Suspense fallback={<></>}>
			{newElements.map(item => {
				return (
					<React.Fragment key={item.id}>
						{parseElements(item, lang, layoutVariant)}
					</React.Fragment>
				);
			})}
		</Suspense>
	);
};

const parseElements = (item, lang, layoutVariant) => {
	const { REACT_APP_PATH, REACT_APP_SKI_PATH } = process.env;

	switch (item?.tag) {
		case "basic-headline": {
			return (
				<Container
					anchor={item?.anchor?.tag}
					key={item?.id}
					maxWidth="md"
					name="headline"
					variant={(item?.variant || 0) + 1}
				>
					<ReactHeadline01 text={item?.text} variant={item.variant} />
				</Container>
			);
		}

		case "basic-headline-overline": {
			return (
				<Container
					anchor={item?.anchor?.tag}
					key={item?.id}
					maxWidth="md"
					name="headline-overline"
				>
					<HeadlineOverline text={item?.text} />
				</Container>
			);
		}

		case "basic-text": {
			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} maxWidth="md" name="text">
					<ReactText01 text={item?.text} />
				</Container>
			);
		}

		case "basic-list": {
			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} maxWidth="md" name="list">
					<ReactList01
						headlineVariant="h5"
						icon={<ReactSVGIcon color="primary" src="/img/icons/list-icon.svg" size={8} />}
						spacing={2}
						textcontent={item?.textcontent}
						title={item?.title}
						variant={item?.variant}
					/>
				</Container>
			);
		}

		case "basic-links": {
			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} maxWidth="md" name="links">
					<ReactReferences01
						elements={item?.elements}
						endIcon={
							<ReactSVGIcon color="primary" src="/img/icons/arrow-right.svg" size={16} />
						}
						hasIcon={false}
						headlineVariant="h5"
						title={item?.title}
					/>
				</Container>
			);
		}

		case "basic-gallery": {
			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} maxWidth="lg" name="gallery">
					<ReactGallery01
						elements={item?.elements}
						mediaFormats={{
							xs: "basic-gallery-thumb"
						}}
						overlayMediaFormats={{
							xs: "basic-gallery"
						}}
					/>
				</Container>
			);
		}

		case "basic-download": {
			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} maxWidth="lg" name="download">
					<ReactFile01 media={item?.media} title={item?.title} />
				</Container>
			);
		}

		case "basic-html": {
			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} maxWidth="lg" name="html">
					<div dangerouslySetInnerHTML={{ __html: item?.text }}></div>
				</Container>
			);
		}

		case "basic-imagetext": {
			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} maxWidth="lg" name="imagetext">
					<ReactImageText01
						addition2={item?.addition2}
						option={item?.option}
						media={item?.media?.[0]}
						mediaFormats={{
							xs: "basic-imagetext-mobile",
							sm: "basic-imagetext"
						}}
						mediaPath={REACT_APP_PATH}
						title={item?.title}
						text={item?.text}
					/>
				</Container>
			);
		}

		case "basic-singleimage": {
			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} maxWidth="lg" name="singleimage">
					<ReactSingleImage01
						addition={item?.addition}
						option2={item?.option2}
						media={item?.media?.[0]}
						mediaFormats={{
							xs: "basic-singleimage-mobile",
							sm: "basic-singleimage"
						}}
						mediaPath={REACT_APP_PATH}
						reference={item?.reference}
						title={item?.title}
						text={item?.text}
					/>
				</Container>
			);
		}

		case "basic-form": {
			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} maxWidth="lg" name="form">
					<ReactFormWeb01
						id={item?.id}
						elements={item?.elements}
						emailMessage={
							lang === "de"
								? "%s entspricht nicht einer E-Mail Adresse"
								: "%s is not a valid mail address"
						}
						errorMessage={
							lang === "de"
								? "Formular konnte nicht versandt werden!"
								: "Form could not be sent!"
						}
						privacyMessage={
							lang === "de"
								? "Ich akzeptiere die <a href='/de/informationen-ueber-cookies-und-datenschutz.html' target='_blank'>Datenschutzbestimmungen</a>"
								: "Yes, I agree with the <a href='/en/cookies-and-data-protection.html' target='_blank'>Privacy Policy</a>"
						}
						requiredMessage={lang === "de" ? "%s ist ein Pflichtfeld" : "%s is required"}
						successMessage={
							lang === "de" ? "Formular erfolgreich versandt!" : "Form sent successfully!"
						}
					/>
				</Container>
			);
		}

		case "basic-singleteaser": {
			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} maxWidth="lg" name="singleteaser">
					<ReactSingleTeaser01
						buttonLabel={locals[lang]?.buttons?.readMore}
						teaser={item?.teaser}
						media={item?.teaser?.media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-singleteaser"
						}}
						variant={item?.variant}
					/>
				</Container>
			);
		}

		case "basic-external-singleteaser": {
			let title = item?.textcontent?.items.find(e => e.name === "Title")?.text;
			let text = item?.textcontent?.items.find(e => e.name === "Text")?.text;
			let link = item?.textcontent?.items.find(e => e.name === "URL")?.text;

			return (
				<Container
					anchor={item?.anchor?.tag}
					key={item?.id}
					maxWidth="lg"
					name="external-singleteaser"
				>
					<ReactSingleTeaser01
						buttonLabel={locals[lang]?.buttons?.readMore}
						teaser={{
							title: title,
							text: text,
							link: link,
							linkTarget: "_blank"
						}}
						media={item?.elements[0]?.media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-singleteaser"
						}}
						variant={item?.variant}
					/>
				</Container>
			);
		}

		case "basic-teaser": {
			let breakpoints = {
				0: {
					slidesPerView: 1.1,
					spaceBetween: 16
				},
				600: {
					slidesPerView: 2,
					spaceBetween: 24
				},
				900: {
					slidesPerView: 3,
					spaceBetween: 24
				}
			};

			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} name="teaser-list">
					<Container maxWidth="lg">
						<ReactTeaserList01
							autoplay={false}
							delay={5000}
							headline={item?.title}
							loop={false}
							navPrev="/img/icons/chevron-left.svg"
							navNext="/img/icons/chevron-right.svg"
							showPagination={true}
							showNavigation={true}
							sliderBreakpoints={breakpoints}
							speed={1000}
							variant="slider"
						>
							{item?.teasergroup?.items?.map(
								({ id, link, media, title, text, linkTarget, from, to }) => {
									const date = {
										dateFrom: from,
										dateTo: to
									};

									return (
										<ReactTeaser01
											button={locals[lang]?.buttons?.readMore}
											buttonSize="medium"
											date={date}
											key={id}
											link={link}
											linkElement={true}
											media={media?.[0]}
											mediaFormats={{
												xs: "basic-teaser"
											}}
											mediaPath={REACT_APP_PATH}
											target={linkTarget}
											text={text}
											title={title}
										/>
									);
								}
							)}
						</ReactTeaserList01>
					</Container>
				</Container>
			);
		}

		case "basic-video": {
			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} maxWidth="lg" name="video">
					<ReactVideo01 title={item?.title} text={item?.text} media={item?.media} />
				</Container>
			);
		}

		case "basic-table": {
			const newTableArray = [];

			for (const entries of item?.textcontent?.items) {
				const rows = entries?.items;
				const columns = rows.map(column => {
					const columnObj = {
						text: column?.text,
						align: column?.["attr-align"],
						width: column?.["attr-width"]
					};
					return columnObj;
				});
				newTableArray.push(columns);
			}

			return (
				<Container
					anchor={item?.anchor?.tag}
					key={item?.id}
					maxWidth="lg"
					name="table"
					variant={layoutVariant}
				>
					<ReactTable01
						addition={item?.addition}
						headlineVariant="h5"
						items={newTableArray}
						title={item?.title}
					/>
				</Container>
			);
		}

		case "basic-dropdown": {
			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} maxWidth="lg" name="dropdown">
					<DropDown data={item}>
						{item?._subItems?.map(subItem => parseElements(subItem, lang, layoutVariant))}
					</DropDown>
				</Container>
			);
		}

		case "extension-button": {
			let link = null;
			let title = null;

			item?.textcontent?.items?.forEach(item => {
				if (item.name === "title") {
					title = item?.text;
				}

				if (item.name === "external-link") {
					link = item?.text;
				}
			});

			item?.elements?.forEach(item => {
				if (item.tag === "pagename") {
					link = item?.reference?.pageName;
				}
			});

			return <Button link={link} title={title} />;
		}

		case "extension-keyfacts": {
			let keyfacts = [];

			item?.elements?.forEach(({ elements, textcontent }) => {
				let media = null;
				let title = null;
				let value = null;

				elements?.forEach(element => {
					if (element.tag === "media") {
						media = element?.media?.[0];
					}
				});

				textcontent?.items?.forEach(item => {
					if (item.name === "title") {
						return (title = item?.text);
					}

					if (item.name === "value") {
						return (value = item?.text);
					}

					return null;
				});

				keyfacts.push({
					media,
					title,
					value
				});
			});

			return (
				<Container
					anchor={item?.anchor?.tag}
					key={item?.id}
					name="keyfacts"
					noPadding
					position="relative"
				>
					<Keyfacts items={keyfacts} />
				</Container>
			);
		}

		case "extension-tickets": {
			let counter = 0;
			let tickets = [];
			let hexagon = {};
			let hexagonText = null;
			let hexagonPrice = null;

			item?.textcontent?.items?.filter(item => {
				if (item.name === "hexagon-text") {
					return (hexagonText = item?.text);
				}

				if (item.name === "hexagon-price") {
					return (hexagonPrice = item?.text);
				}

				return null;
			});

			hexagon = {
				text: hexagonText,
				price: hexagonPrice
			};

			item?.elements?.map(element => {
				let background = "default";
				let ticketTitle = null;
				let ticketText = null;
				let ticketLink = null;
				let ticketAddition = null;
				let ticketPrice = null;
				let ticketPriceAddition = null;
				let ticket = {};

				element?.textcontent?.items?.filter(item => {
					if (item.name === "background") {
						return (background = item?.text);
					}

					if (item.name === "title") {
						return (ticketTitle = item?.text);
					}

					if (item.name === "description") {
						return (ticketText = item?.text);
					}

					if (item.name === "addition") {
						return (ticketAddition = item?.text);
					}

					if (item.name === "price") {
						return (ticketPrice = item?.text);
					}

					if (item.name === "priceaddition") {
						return (ticketPriceAddition = item?.text);
					}

					if (item.name === "link") {
						return (ticketLink = item?.text);
					}

					return null;
				});

				ticket = {
					id: counter++,
					background,
					title: ticketTitle,
					text: ticketText,
					addition: ticketAddition,
					price: ticketPrice,
					priceAddition: ticketPriceAddition,
					link: ticketLink
				};

				tickets.push(ticket);

				return null;
			});

			return (
				<Container
					anchor={item?.anchor?.tag}
					key={item?.id}
					name="tickets"
					noPadding
					position="relative"
				>
					<Tickets items={tickets} hexagon={hexagon} />
				</Container>
			);
		}

		case "extension-additional-benefits": {
			let titleLeft = null;
			let titleRight = null;
			let textLeft = null;
			let textRight = null;

			item?.textcontent?.items?.filter(item => {
				if (item.name === "title-left") {
					return (titleLeft = item?.text);
				}

				if (item.name === "title-right") {
					return (titleRight = item?.text);
				}

				if (item.name === "text-left") {
					return (textLeft = item?.text);
				}

				if (item.name === "text-right") {
					return (textRight = item?.text);
				}

				return null;
			});

			let benefits = [
				{
					title: titleLeft,
					text: textLeft
				},
				{
					title: titleRight,
					text: textRight
				}
			];

			return (
				<Container
					anchor={item?.anchor?.tag}
					key={item?.id}
					name="additional-benefits"
					noPadding
				>
					<AdditionalBenefits
						variant={item?.variant === 1 ? "hexagon" : ""}
						items={benefits}
					/>
				</Container>
			);
		}

		case "extension-animation-data": {
			let media = item?.elements?.filter(element => element.tag === "media")?.[0]?.media?.[0];
			let priceAddition = null;
			let price = null;

			item?.textcontent?.items?.forEach(item => {
				if (item.name === "price-addition") {
					priceAddition = item?.text;
				}

				if (item.name === "price") {
					price = item?.text;
				}
			});

			return <AnimationData media={media} priceAddition={priceAddition} price={price} />;
		}

		case "extension-search-result": {
			return (
				<Container
					anchor={item?.anchor?.tag}
					key={item?.id}
					maxWidth="lg"
					name="search-result"
				>
					<ReactSearchResult
						api="/searchv2.json.api"
						components={[{ identifier: "default", component: <SearchResultContent /> }]}
						lang={lang}
						placeholder={locals[lang]?.placeholders?.searchField}
					/>
				</Container>
			);
		}

		case "extension-list": {
			let headline = item?.textcontent?.items.find(e => e.name === "headline")?.text;
			let text = item?.textcontent?.items.find(e => e.name === "text")?.text;
			let list = item?.textcontent?.items.find(e => e.name === "list")?.text;

			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} maxWidth="lg" name="list">
					<List headline={headline} list={list} text={text} />
				</Container>
			);
		}

		case "ski-webcam-slider": {
			return (
				<Container
					anchor={item?.anchor?.tag}
					key={item?.id}
					name="ski-webcam-slider"
					noPadding
					position="relative"
				>
					<WebcamSlider
						client={REACT_APP_SKI_PATH}
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.WebcamApi/ListWebcams.api`}
					/>
				</Container>
			);
		}

		case "ski-region-slider": {
			let region = "3taeler";

			let mode = null;

			item?.textcontent?.items?.filter(item => {
				if (item.name === "state") {
					return (mode = item?.text);
				}

				return null;
			});

			if (item?.variant === 1) {
				return (
					<Container
						anchor={item?.anchor?.tag}
						key={item?.id}
						name="ski-region-list"
						noPadding
						position="relative"
					>
						<SkiRegionList lang={lang} region={region} />
					</Container>
				);
			}

			return (
				<Container
					anchor={item?.anchor?.tag}
					key={item?.id}
					name="ski-region-slider"
					noPadding
					position="relative"
				>
					<SkiRegionSlider lang={lang} mode={mode} region={region} />
				</Container>
			);
		}

		case "mski-rates": {
			let region = null;
			let identifier = null;

			item?.textcontent?.items?.filter(item => {
				if (item.name === "regions") {
					return (region = item?.text);
				}

				if (item.name === "rates") {
					return (identifier = item?.text);
				}

				return null;
			});

			return (
				<Container
					anchor={item?.anchor?.tag}
					key={item?.id}
					maxWidth="lg"
					name="rates"
					variant={layoutVariant}
				>
					<ReactRates01
						client={REACT_APP_SKI_PATH}
						headlineVariant="h5"
						lang={lang}
						rate={identifier}
						region={region}
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.RateApi/GetRateTableJSON.api`}
					/>
				</Container>
			);
		}

		case "extension-interactive-map": {
			const mapPoints = MapPoints();
			const mapPointsWinter = mapPoints?.winter;
			const mapPointsSummer = mapPoints?.summer;

			let headline = null;
			let isHeadlineH1 = false;
			let summer = false;
			let ticketType = null;
			let winter = false;

			item?.textcontent?.items?.filter(item => {
				if (item.name === "headline") {
					return (headline = item?.text);
				}

				if (item.name === "isHeadlineH1") {
					return (isHeadlineH1 = item?.text === "True" ? true : false);
				}

				if (item.name === "winter") {
					return (winter = item?.text === "True");
				}

				if (item.name === "summer") {
					return (summer = item?.text === "True");
				}

				if (item.name === "ticket-type") {
					return (ticketType = item?.text);
				}

				return null;
			});

			return (
				<Container anchor={item?.anchor?.tag} key={item?.id} name="interactive-map" noPadding>
					<InteractiveMap
						headline={headline}
						isHeadlineH1={isHeadlineH1}
						mapPointsWinter={mapPointsWinter}
						mapPointsSummer={mapPointsSummer}
						lang={lang}
						summer={summer}
						ticketType={ticketType}
						variant={item?.variant === 1 ? "summer" : "winter"}
						winter={winter}
						url={
							REACT_APP_PATH +
							"/SkigebieteManager/Micado.SkigebieteManager.Plugin.RegionPlugin.RegionApiIO/List.api?region=3taeler"
						}
					/>
				</Container>
			);
		}

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.log("missing element:", item);
			}

			return null;
	}
};

export default Elements;
