import React, { Suspense, useContext, lazy } from "react";
import clsx from "clsx";
import { Element } from "react-scroll";
import makeStyles from "@material-ui/styles/makeStyles";
import WebProfileContext from "@micado-digital/react-ui/context/WebProfileContext";
import PageContext from "@micado-digital/react-ui/context/PageContext";
import SkiShopContext from "@micado-digital/react-ski-shop/context/SkiShopContext";
import Elements from "./elements";

const Cart01 = lazy(() =>
	import(
		/* webpackChunkName: "reactSkiShopCart" */ "@micado-digital/react-ski-shop/views/Cart01"
	)
);
const ReactPwaAlert = lazy(() =>
	import(
		/* webpackChunkName: "reactPwaAlert" */ "@micado-digital/react-pwa-alert/ReactPwaAlert"
	)
);

const useStyles = makeStyles(theme => ({
	content: {
		background: theme.palette?.background?.body,
		padding: theme?.spacing(10, 0),
		position: "relative",
		scrollMarginTop: "70px",
		zIndex: 3,
		[theme.breakpoints.up("sm")]: {
			padding: theme?.spacing(15, 0, 10)
		}
	},
	noAnimation: {
		paddingTop: 210
	},
	noAnimationTop: {
		paddingTop: 70,
		[theme.breakpoints.up("lg")]: {
			paddingTop: 90
		}
	},
	hasAnimationData: {
		[theme.breakpoints.down("md")]: {
			paddingTop: 180
		}
	}
}));

const Standard = ({ variant }) => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { lang } = pageData || {};
	const [profileData] = useContext(WebProfileContext);
	const { skiShopData, setSkiShopData } = useContext(SkiShopContext);
	const { showCart } = skiShopData || {};
	const css = useStyles();

	const handleCartClose = () => {
		setSkiShopData(prevState => ({
			...prevState,
			showCart: false
		}));
	};

	return (
		<>
			<Element name="content">
				<main
					className={clsx("mco-content", css.content, {
						[css.noAnimation]: variant === "no-animation",
						[css.noAnimationTop]: variant === "no-animation-top",
						[css.hasAnimationData]: ["summer", "winter"].indexOf(variant) >= 0
					})}
				>
					<Elements items={pageData?.elements} lang={pageData?.lang} layoutVariant={variant} />
					{profileData?.pwaShowInstallHint && (
						<Suspense fallback={<></>}>
							<ReactPwaAlert appIcon={REACT_APP_PATH + "/img/pwa/apple-icon-180.png"} />
						</Suspense>
					)}
				</main>
				<micado-ial
					key={pageData?.id}
					page-id={pageData?.id}
					path={REACT_APP_PATH}
				></micado-ial>
			</Element>
			{showCart && (
				<Suspense fallback={<></>}>
					<Cart01 lang={lang} onClose={handleCartClose} />
				</Suspense>
			)}
		</>
	);
};

export default Standard;
