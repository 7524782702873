import React, { useContext, useEffect, useState, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import CssBaseline from "@material-ui/core/CssBaseline";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { alpha } from "@material-ui/core/styles/";
import { theme as customTheme } from "./themes/dreiTaelerpass";
import DetectPage from "./helper/detectPage";
import ChangePage from "./helper/changePage";
import CSSProperties from "./utils/cssProperties";
import MenuContext from "./context/menuContext";
import PageContext from "@micado-digital/react-ui/context/PageContext";
import BookmarkRoot from "@micado-digital/react-bookmark/ReactBookmark/bookmarkRoot";
import Ui from "@micado-digital/react-ui/components/Ui";
import UserData from "@micado-digital/react-ui/components/UserData";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import InternalRoot from "@micado-digital/react-internal/ReactInternal/internalRoot";
import SkiShopData from "@micado-digital/react-ski-shop/components/SkiShopData";
import {
	applyPolyfills as ialApplyPolyfills,
	defineCustomElements as ialDefineCustomElements
} from "@micado-digital/micado-ial/loader";
import "./css/index.css";
import "./css/ski-shop/main.css";

const theme = createTheme(customTheme);

const Core = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [pageData] = useContext(PageContext);
	const { lang = "de" } = pageData;
	const skiShopURLPath = "ski-shop";

	return (
		<WebProfile key={lang}>
			<UserData>
				<InternalRoot>
					<BookmarkRoot>
						<MenuContext.Provider value={[menuOpen, setMenuOpen]}>
							<SkiShopData
								creditcards={["mastercard", "visa", "dinersclub", "discover"]}
								lang={lang}
								oAuth
								showFamilyCard
								urlPath={skiShopURLPath}
							>
								<CssBaseline />
								<ChangePage />
								<Routes>
									<Route path="/*" element={<DetectPage />} />
								</Routes>
							</SkiShopData>
						</MenuContext.Provider>
					</BookmarkRoot>
				</InternalRoot>
			</UserData>
		</WebProfile>
	);
};

const App = () => {
	useEffect(() => {
		CSSProperties(theme, alpha);
	}, []);

	return (
		<QueryClientProvider client={new QueryClient()}>
			<ThemeProvider theme={theme}>
				<Suspense fallback={<></>}>
					<BrowserRouter>
						<Ui>
							<WebPageData>
								<Core />
							</WebPageData>
						</Ui>
					</BrowserRouter>
				</Suspense>
			</ThemeProvider>
		</QueryClientProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));

ialApplyPolyfills().then(() => {
	ialDefineCustomElements(window);
});
