function CSSProperties(theme, alpha) {
	const $style = document.createElement("style");
	$style.innerHTML = `
		:root {
			--mco-max-width: ${theme?.breakpoints?.values?.lg}px;
			--mco-breakpoints-xs: ${theme?.breakpoints?.values?.xs}px;
			--mco-breakpoints-sm: ${theme?.breakpoints?.values?.sm}px;
			--mco-breakpoints-md: ${theme?.breakpoints?.values?.md}px;
			--mco-breakpoints-lg: ${theme?.breakpoints?.values?.lg}px;
			--mco-breakpoints-xl: ${theme?.breakpoints?.values?.xl}px;
			--mco-shape-border-radius: ${theme?.shape?.borderRadius}px;
			--mco-spacing: ${theme?.spacing}px;
			--mco-palette-primary-light: ${theme?.palette?.primary?.light};
			--mco-palette-primary-main: ${theme?.palette?.primary?.main};
			--mco-palette-primary-dark: ${theme?.palette?.primary?.dark};
			--mco-palette-secondary-light: ${theme?.palette?.secondary?.light};
			--mco-palette-secondary-main: ${theme?.palette?.secondary?.main};
			--mco-palette-secondary-dark: ${theme?.palette?.secondary?.dark};
			--mco-palette-tertiary-main: ${theme?.palette?.tertiary?.main};
			--mco-palette-tertiary-dark: ${theme?.palette?.tertiary?.dark};
			--mco-palette-quaternary-main: ${theme?.palette?.quaternary?.main};
			--mco-palette-quaternary-dark: ${theme?.palette?.quaternary?.dark};
			--mco-palette-success-main: ${theme?.palette?.success?.main};
			--mco-palette-info-main: ${theme?.palette?.info?.main};
			--mco-palette-warning-main: ${theme?.palette?.warning?.main};
			--mco-palette-error-main: ${theme?.palette?.error?.main};
			--mco-palette-primary-alpha-10: ${alpha(theme?.palette?.secondary?.main, 0.1)};
			--mco-palette-primary-alpha-20: ${alpha(theme?.palette?.secondary?.main, 0.2)};
			--mco-palette-text-primary: ${theme?.palette?.text?.primary};
			--mco-palette-text-secondary: ${theme?.palette?.text?.secondary};
			--mco-palette-background-body: ${theme?.palette?.background?.body};
			--mco-palette-background-backdrop: ${theme?.palette?.background?.backdrop};
			--mco-palette-gray-50: ${theme?.palette?.grey[50]};
			--mco-palette-gray-100: ${theme?.palette?.grey[100]};
			--mco-palette-gray-200: ${theme?.palette?.grey[200]};
			--mco-palette-gray-300: ${theme?.palette?.grey[300]};
			--mco-palette-gray-400: ${theme?.palette?.grey[400]};
			--mco-palette-gray-500: ${theme?.palette?.grey[500]};
			--mco-palette-gray-600: ${theme?.palette?.grey[600]};
			--mco-palette-gray-700: ${theme?.palette?.grey[700]};
			--mco-palette-gray-800: ${theme?.palette?.grey[800]};
			--mco-palette-gray-900: ${theme?.palette?.grey[900]};
			--mco-transition-duration: ${theme?.transitions?.duration?.standard}ms;
			--mco-transition-easing: ${theme?.transitions?.easing?.easeInOut};
			--mco-transition: all var(--mco-transition-duration) var(--mco-transition-easing);
			--mco-typography-body1: ${theme?.typography?.body1?.fontWeight} ${
		theme?.typography?.body1?.fontSize
	}px/${theme?.typography?.body1?.lineHeight} ${theme?.typography?.body1?.fontFamily};
	--mco-typography-body2: ${theme?.typography?.body2?.fontWeight} ${
		theme?.typography?.body2?.fontSize
	}px/${theme?.typography?.body2?.lineHeight} ${theme?.typography?.body2?.fontFamily};
	--mco-typography-button: ${theme?.typography?.button?.fontWeight} ${
		theme?.typography?.button?.fontSize
	}px/${theme?.typography?.button?.lineHeight} ${theme?.typography?.button?.fontFamily};
			--mco-zIndex-modal: ${theme?.zIndex?.modal};
			--mco-zIndex-tooltip: ${theme?.zIndex?.tooltip};
		}	
	`;
	document.head.appendChild($style);
}

export default CSSProperties;
