import React, { Suspense, useContext, useEffect, lazy } from "react";
import { useScrollYPosition } from "react-use-scroll-position";
import PropTypes from "prop-types";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuContext from "../../../context/menuContext";

const Anchors = lazy(() => import(/* webpackChunkName: "reactAnchors" */ "../anchors"));
const Icons = lazy(() => import(/* webpackChunkName: "reactIcons" */ "./icons"));
const Logo = lazy(() => import(/* webpackChunkName: "reactLogo" */ "./logo"));
const Mobile = lazy(() => import(/* webpackChunkName: "reactMobile" */ "./mobile"));
const Desktop = lazy(() => import(/* webpackChunkName: "reactDesktop" */ "./desktop"));
const Hamburger = lazy(() => import(/* webpackChunkName: "reactHamburger" */ "./hamburger"));

const useStyles = makeStyles(theme => ({
	root: {
		alignItems: "center",
		background: theme?.palette?.background?.header,
		boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.24)",
		display: "flex",
		height: "100%",
		left: 0,
		maxHeight: "calc(env(safe-area-inset-top) + 70px)",
		padding:
			"env(safe-area-inset-top) " + theme?.spacing(2) + "px 0 " + theme?.spacing(2) + "px",
		position: "fixed",
		right: 0,
		top: 0,
		transition: theme.transitions.create(["max-height"], {
			duration: theme.transitions.duration.complex
		}),
		zIndex: theme?.zIndex?.appBar,
		[theme.breakpoints.up("sm")]: {
			paddingLeft: theme?.spacing(3),
			paddingRight: theme?.spacing(3)
		},
		[theme.breakpoints.up("lg")]: {
			alignItems: "flex-end",
			maxHeight: "calc(env(safe-area-inset-top) + 90px)"
		}
	},
	transparentHeader: {
		background: "none",
		boxShadow: "none",
		"& .menu-01-desktop-menuitem__link": {
			color: theme?.palette?.common?.white,
			"&::after": {
				background: theme?.palette?.common?.white,
				opacity: 1
			},
			"&:hover, &:focus": {
				background: theme?.palette?.common?.white,
				color: theme?.palette?.primary?.main
			},
			"&--selected": {
				background: theme?.palette?.common?.white,
				color: theme?.palette?.primary?.main,
				"&::after": {
					background: `${theme?.palette?.brand2} !important`
				}
			}
		},
		"& .mco-header__search svg path": {
			color: theme?.palette?.common?.white
		},
		"& .mco-header__language": {
			color: theme?.palette?.common?.white
		},
		"& .mco-header__icons": {
			"& svg path": {
				fill: theme?.palette?.common?.white
			}
		}
	},
	bigHeader: {
		maxHeight: "calc(env(safe-area-inset-top) + 135px)",
		"& .mco-header__logo-link": {
			left: 34,
			maxWidth: 150,
			top: 17
		},
		"& .menu-01-desktop": {
			marginLeft: theme?.spacing(21.25)
		}
	},
	desktop: {
		order: 2
	},
	mobile: {
		margin: theme?.spacing(0, -1.5, 0, 1.25),
		order: 4
	}
}));

const Header = ({ fixed, mainmenu }) => {
	const [menuOpen, setMenuOpen] = useContext(MenuContext);
	const theme = useTheme();
	const bigScreen = useMediaQuery(theme.breakpoints.up("lg"));
	const css = useStyles();

	const scrollY = useScrollYPosition();
	const transparentHeader = fixed ? false : bigScreen && scrollY <= 10;

	useEffect(() => {
		if (menuOpen) {
			document.body.classList.add("submenu--open");
		} else {
			document.body.classList.remove("submenu--open");
		}
	}, [menuOpen]);

	useEffect(() => {
		if (transparentHeader) {
			document.body.classList.add("header--transparent");
		} else {
			document.body.classList.remove("header--transparent");
		}
	}, [transparentHeader]);

	const mobileMenu = (
		<div className={css.mobile}>
			<Suspense fallback={<></>}>
				<Hamburger isOpen={menuOpen} setOpen={setMenuOpen} />
				{menuOpen && (
					<Mobile isOpen={menuOpen} mainmenu={mainmenu} icon="/img/icons/menu-dropdown.svg" />
				)}
			</Suspense>
		</div>
	);

	const desktopMenu = (
		<div className={css.desktop}>
			<Suspense fallback={<></>}>
				<Desktop
					mainmenu={mainmenu}
					desktopSubContainerEvent="mouseenter"
					iconClose="/img/icons/close.svg"
					onClose={() => setMenuOpen(false)}
					onOpen={() => setMenuOpen(true)}
				/>
			</Suspense>
		</div>
	);

	return (
		<>
			<header
				className={clsx("mco-header", css.root, {
					[css.bigHeader]: transparentHeader,
					[css.transparentHeader]: menuOpen ? false : transparentHeader,
					[css.menuOpen]: menuOpen
				})}
			>
				<Logo file="/img/drei-taelerpass.svg" />
				{bigScreen ? desktopMenu : mobileMenu}
				<Icons menuOpen={menuOpen} />
			</header>
			<Anchors fixedHeader={!transparentHeader} />
		</>
	);
};

Header.propTypes = {
	fixed: PropTypes.bool,
	mainmenu: PropTypes.object.isRequired,
	metamenu: PropTypes.object
};

export default Header;
