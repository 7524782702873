import React, { Suspense, useContext, lazy } from "react";
import clsx from "clsx";
import { Element } from "react-scroll";
import makeStyles from "@material-ui/styles/makeStyles";
import WebProfileContext from "@micado-digital/react-ui/context/WebProfileContext";
import PageContext from "@micado-digital/react-ui/context/PageContext";

const ReactPwaAlert = lazy(() =>
	import(
		/* webpackChunkName: "reactPwaAlert" */ "@micado-digital/react-pwa-alert/ReactPwaAlert"
	)
);
const SkiShopApp = lazy(() =>
	import(
		/* webpackChunkName: "reactSkiShop" */ "@micado-digital/react-ski-shop/components/SkiShop"
	)
);

const useStyles = makeStyles(theme => ({
	content: {
		background: theme.palette?.background?.body,
		padding: theme?.spacing(10, 0, 0),
		position: "relative",
		scrollMarginTop: "70px",
		zIndex: 3,
		[theme.breakpoints.up("sm")]: {
			padding: theme?.spacing(15, 0, 0)
		}
	},
	noAnimation: {
		paddingTop: 70,
		[theme.breakpoints.up("lg")]: {
			paddingTop: 90
		}
	},
	noHeader: {
		paddingTop: "0 !important"
	}
}));

const SkiShop = ({ hasHeader }) => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { lang } = pageData || {};
	const [profileData] = useContext(WebProfileContext);
	const css = useStyles();

	return (
		<Element name="content">
			<main
				className={clsx("mco-content", css.content, css.noAnimation, {
					[css.noHeader]: !hasHeader
				})}
			>
				<SkiShopApp lang={lang} />
				{profileData?.pwaShowInstallHint && (
					<Suspense fallback={<></>}>
						<ReactPwaAlert appIcon={REACT_APP_PATH + "/img/pwa/apple-icon-180.png"} />
					</Suspense>
				)}
			</main>
			<micado-ial key={pageData?.id} page-id={pageData?.id} path={REACT_APP_PATH}></micado-ial>
		</Element>
	);
};

export default SkiShop;
