import React, { useContext, lazy } from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PageContext from "@micado-digital/react-ui/context/PageContext";
import locals from "../../locals";

const ReactLink = lazy(() =>
	import(/* webpackChunkName: "reactLink" */ "@micado-digital/react-link/ReactLink")
);

const useStyles = makeStyles(theme => ({
	content: {
		border: "1px solid " + theme.palette?.grey?.[300],
		display: "flex",
		flexDirection: "column",
		padding: theme.spacing(3)
	},
	text: {
		color: theme.palette?.text?.primary
	},
	button: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "auto",
		paddingTop: theme.spacing(2)
	}
}));

const SearchResultContent = ({ description, pageName, title }) => {
	const pageData = useContext(PageContext)[0];
	const css = useStyles();

	return (
		<ReactLink to={pageName}>
			<Box className="mco-singleteaser-01" mb={2}>
				<Box className={clsx("mco-singleteaser-01__content", css.content)}>
					<Typography
						className="mco-singleteaser-01__title"
						color="secondary"
						component="h3"
						dangerouslySetInnerHTML={{ __html: title }}
						variant="h4"
					/>
					<Box mt={1}>
						<Typography
							className={clsx(css.text, "mco-singleteaser-01__text")}
							dangerouslySetInnerHTML={{ __html: description }}
						/>
					</Box>
					<Box className={css.button}>
						<ReactLink to={pageName}>
							<Button color="primary" fullWidth variant="contained">
								{locals[pageData?.lang]?.buttons?.readMore}
							</Button>
						</ReactLink>
					</Box>
				</Box>
			</Box>
		</ReactLink>
	);
};

export default SearchResultContent;
