export const theme = {
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1190,
			xl: 1920
		}
	},
	typography: {
		fontFamily: "'Intro', sans-serif",
		fontSize: 16,
		fontWeightLight: 400,
		fontWeightRegular: 400,
		fontWeightMedium: 700,
		fontWeightBold: 700,
		"display-large": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: "clamp(26px, 8vw, 44px)",
			hyphens: "auto",
			lineHeight: 1,
			letterSpacing: 0,
			textTransform: "uppercase"
		},
		"display-medium": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: "clamp(24px, 7vw, 40px)",
			lineHeight: 1,
			letterSpacing: 0,
			textTransform: "uppercase"
		},
		"display-small": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: "clamp(22px, 6vw, 36px)",
			lineHeight: 1.1,
			letterSpacing: 0,
			textTransform: "uppercase"
		},
		"headline-large": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: "clamp(20px, 5vw, 28px)",
			lineHeight: 1,
			letterSpacing: 0,
			textTransform: "uppercase"
		},
		"headline-medium": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: "clamp(18px, 5vw, 20px)",
			lineHeight: 1.2,
			letterSpacing: 0,
			textTransform: "uppercase"
		},
		"headline-small": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: 16,
			lineHeight: 1.25,
			letterSpacing: 0,
			textTransform: "uppercase"
		},
		"title-large": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: 16,
			lineHeight: 1.5,
			letterSpacing: 0
		},
		"title-medium": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: 14,
			lineHeight: "18px",
			letterSpacing: 0
		},
		"title-small": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: 14,
			lineHeight: "16px",
			letterSpacing: 0,
			textTransform: "uppercase"
		},
		"body-large": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 400,
			fontSize: 18,
			lineHeight: 1.5,
			letterSpacing: 0
		},
		"body-medium": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 400,
			fontSize: 16,
			lineHeight: 1.5,
			letterSpacing: 0
		},
		"body-small": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 400,
			fontSize: 14,
			lineHeight: "20px",
			letterSpacing: 0
		},
		"label-large": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: 18,
			lineHeight: 1,
			letterSpacing: 0.4
		},
		"label-medium": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: 16,
			lineHeight: 1,
			letterSpacing: 0.4
		},
		"label-small": {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: 14,
			lineHeight: 1,
			letterSpacing: 0.4
		},
		h1: {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: "clamp(26px, 8vw, 44px)",
			hyphens: "auto",
			lineHeight: 1,
			letterSpacing: 0,
			textTransform: "uppercase"
		},
		h2: {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: "clamp(24px, 7vw, 40px)",
			lineHeight: 1,
			letterSpacing: 0,
			textTransform: "uppercase"
		},
		h3: {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: "clamp(22px, 6vw, 36px)",
			lineHeight: 1.1,
			letterSpacing: 0,
			textTransform: "uppercase"
		},
		h4: {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: "clamp(20px, 5vw, 28px)",
			lineHeight: 1,
			letterSpacing: 0,
			textTransform: "uppercase"
		},
		h5: {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: "clamp(18px, 5vw, 20px)",
			lineHeight: 1.2,
			letterSpacing: 0,
			textTransform: "uppercase"
		},
		h6: {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: 16,
			lineHeight: 1.25,
			letterSpacing: 0,
			textTransform: "uppercase"
		},
		body1: {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 400,
			fontSize: 16,
			lineHeight: 1.5,
			letterSpacing: 0
		},
		body2: {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 400,
			fontSize: 14,
			lineHeight: "20px",
			letterSpacing: 0
		},
		subtitle1: {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: 16,
			lineHeight: 1.5,
			letterSpacing: 0
		},
		subtitle2: {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: 14,
			lineHeight: "18px",
			letterSpacing: 0
		},
		overline: {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: 14,
			lineHeight: "16px",
			letterSpacing: 0,
			textTransform: "uppercase"
		},
		caption: {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 400,
			fontSize: 12,
			lineHeight: "16px",
			letterSpacing: 0.4,
			textTransform: "uppercase"
		},
		button: {
			fontFamily: "'Intro', sans-serif",
			fontWeight: 900,
			fontSize: 16,
			lineHeight: 1,
			letterSpacing: 0.4
		}
	},
	palette: {
		brand1: "#005183",
		brand2: "#CAD5DE",
		primary: {
			light: "#7E97AD",
			main: "#007BAF",
			dark: "#005183",
			contrastText: "#FFFFFF"
		},
		secondary: {
			light: "#F6B855",
			main: "#E6910C",
			dark: "#BD7505",
			contrastText: "#FFFFFF"
		},
		tertiary: {
			light: "#91B419",
			main: "#6D931F",
			dark: "#587619",
			contrastText: "#100B0D"
		},
		quaternary: {
			light: "#A3CFE4",
			main: "#4BBEDB",
			dark: "#0096CC",
			contrastText: "#100B0D"
		},
		error: {
			light: "#F08080",
			main: "#D50B0B",
			dark: "#8B0101"
		},
		warning: {
			light: "#FFDE77",
			main: "#FFC105",
			dark: "#C17915"
		},
		info: {
			light: "#4BBEDB",
			main: "#00A2DD",
			dark: "#086D91"
		},
		success: {
			light: "#B2D975",
			main: "#71B800",
			dark: "#477301"
		},
		grey: {
			50: "#F9F9F9",
			100: "#F2F2F2"
		},
		text: {
			primary: "#333333",
			secondary: "#666666",
			disabled: "#BFBFBF"
		},
		divider: "#F2F2F2",
		background: {
			backdrop: "rgba(0, 0, 0, 0.65)",
			body: "#FFFFFF",
			header: "#FFFFFF",
			footer01: {
				footer: "#04314E"
			},
			menu: "#04314E",
			menuMobileBackdropLayer: "#F2F2F2",
			teaser: "#F2F2F2",
			default: "#FFFFFF",
			dark: "#04314E"
		}
	},
	shape: {
		borderRadius: 0
	},
	overrides: {
		MuiButton: {
			root: {
				boxShadow: "none !important",
				height: 48
			},
			contained: {
				borderBottom: "3px solid #666666"
			},
			containedPrimary: {
				borderColor: "#005183"
			},
			containedSecondary: {
				borderColor: "#C77C05"
			},
			sizeSmall: {
				height: 40
			},
			sizeLarge: {
				height: 64
			}
		},
		MuiAlert: {
			root: {
				alignItems: "center"
			}
		}
	},
	zIndex: {
		filter: 1099
	},
	customTypography: {
		button: {
			small: {
				fontSize: 14,
				fontWeight: 900,
				letterSpacing: "0.4px",
				textTransform: "uppercase"
			}
		},
		counter: {
			fontFamily: "'Roboto', sans-serif",
			fontSize: 10,
			fontWeight: 700,
			lineHeight: 1
		},
		mainmenu: {
			mobile: {
				level1: {
					fontWeight: 900,
					fontSize: 15,
					lineHeight: "18px",
					textTransform: "uppercase"
				},
				level2: {
					fontWeight: 400,
					fontSize: 14,
					lineHeight: "20px",
					textTransform: "uppercase"
				},
				level3: {
					fontWeight: 400,
					fontSize: 16
				}
			},
			desktop: {
				level1: {
					fontSize: 15,
					fontWeight: 900,
					lineHeight: "18px",
					textTransform: "uppercase"
				},
				level2: {
					color: "#7E97AD",
					fontSize: 14,
					fontWeight: 400,
					letterSpacing: 0,
					textTransform: "uppercase"
				},
				level3: {
					fontSize: 14,
					fontWeight: 400,
					letterSpacing: 0
				}
			}
		},
		language: {
			label: {
				fontFamily: "'Intro', sans-serif",
				fontSize: 16,
				fontWeight: 900,
				textTransform: "uppercase"
			}
		},
		footer01: {
			name: {
				color: "#B2B2B2",
				fontFamily: "'Intro', sans-serif",
				fontWeight: 400,
				fontSize: 14,
				lineHeight: 1.4,
				margin: "0 0 16px"
			},
			footermenu: {
				mobile: {
					level1: {
						color: "#B2B2B2",
						fontFamily: "'Intro', sans-serif",
						fontWeight: 400,
						fontSize: 14,
						lineHeight: 1.4,
						margin: "0 0 8px",
						transition: "color 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
						"&:hover": {
							color: "#4BBEDB",
							textDecoration: "none"
						}
					},
					level2: {
						color: "#FFFFFF",
						fontFamily: "'Intro', sans-serif",
						fontWeight: 900,
						fontSize: 16,
						lineHeight: 1.5,
						margin: "6px 0",
						textTransform: "uppercase",
						transition: "color 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
						"&:hover": {
							color: "#4BBEDB",
							textDecoration: "none"
						}
					}
				}
			},
			metamenu: {
				mobile: {
					level1: {
						color: "#FFFFFF",
						fontFamily: "'Intro', sans-serif",
						fontWeight: 400,
						fontSize: 12,
						lineHeight: 1.3333333333333333,
						textTransform: "uppercase",
						transition: "color 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
						"&:hover": {
							color: "#4BBEDB",
							textDecoration: "none"
						}
					}
				}
			}
		},
		form: {
			field: {
				fontWeight: 400,
				fontSize: 18,
				lineHeight: 1.5
			},
			label: {
				fontWeight: 400,
				fontSize: 12,
				lineHeight: 1.167,
				letterSpacing: 0.4
			}
		},
		webcam: {
			title: {
				fontSize: 20,
				lineHeight: 1.2
			}
		}
	}
};
